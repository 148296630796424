.hexagon {
    clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
    background-size: cover;
    background-color: #391c51;
}

.small {
    width: 40px;
    height: 40px;
    background-size: 110%;
    background-position: center;
    /* margin-left: 5px; */
}

.small-container {
    width: 45px;
    height: 45px;
}

.small-border {
    clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
    background-color: grey;
    height: 44px;
    width: 44px;

    position: relative;
    top: -42px;
    z-index: -1;
    left: -2px
}

.small-border-hightlighted {
    animation: opacityblink 1s infinite;
    clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
    background-color: black;
    height: 80px;
    width: 80px;

    position: relative;
    top: -75px;
    z-index: -1;
}

.medium {
    width: 70px;
    height: 70px;
    background-size: 110%;
    background-position: center;
    margin-left: 5px;
}

.medium-container {
    width: 80px;
    height: 80px;
}

.medium-border {
    clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
    background-color: grey;
    height: 80px;
    width: 80px;

    position: relative;
    top: -75px;
    z-index: -1;
}

.medium-border-hightlighted {
    animation: opacityblink 1s infinite;
    clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
    background-color: black;
    height: 80px;
    width: 80px;

    position: relative;
    top: -75px;
    z-index: -1;
}

.large {
    width: 120px;
    height: 120px;
    position: relative;
    top: 5px;
    left: 5px;
}

.large-container {
    width: 130px;
    height: 130px;
}

.large-border {
    clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
    background-color: grey;
    height: 130px;
    width: 130px;

    position: relative;
    top: -120px;
    z-index: -1;
}

.large-border-hightlighted {
    animation: opacityblink 1s infinite;
    clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
    background-color: black;
    height: 130px;
    width: 130px;

    position: relative;
    top: -120px;
    z-index: -1;
}