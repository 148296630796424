.no-pick {
    font-size: 75px;
    text-shadow: 2px 2px 2px white;
}

.shift {
    margin-left: 65px;
}

.matchup-info {
    display: flex;
    width: 60%;
    margin: auto;
}

.hero-pool::-webkit-scrollbar {
    display: none;
}

.hero-circle-disabled {
    border-color: grey;
    opacity: 0.5;
}

.hero-circle-suggestion {
    animation: suggestionblink 1s infinite;
}

@keyframes suggestionblink {
    0% {}

    50% {
        filter: brightness(150%);
        /* opacity: 0.5; */
        border-color: green;
    }
}

@keyframes opacityblink {
    0% {}

    50% {
        opacity: 0.5;
    }
}

/* .matchup-header {
    display: flex;
} */

.matchup-content {
    display: flex;
}

.matchup-reset,
.matchup-revert {
    cursor: pointer;
}

.matchup-reset:hover,
.matchup-revert:hover {
    background-color: lightgrey;
}


@media only screen and (min-width: 480px),
only screen and (min-device-width: 480px) {
    /* .matchup-header {
        display: flex;
        width: 100%;
        margin: auto;
        height: 150px;
    } */

    .matchup-first-choice {
        height: 30px;
    }

    .ban-blue,
    .ban-red {
        margin: 0;
    }

    .matchup-info {
        margin-bottom: 20px;
    }

    .matchup-content {
        display: flex;
        width: 100%;
        margin: auto;
    }

    /*
      .matchup-content {
        display: block;
        position: relative;
    }

  
    .ban-blue {
        margin-left: 0px;
        position: absolute;
        left: 44%;
    }

    .ban-red {
        margin-left: 0px;
        position: absolute;
        left: 70%;
    }

    .team-blue {
        margin-top: 100px;
        position: absolute;
        left: 50%;
    }

    .team-red {
        margin-top: 130px;
        position: absolute;
        left: 70%;
    }

    .matchup-action {
        position: absolute;
        top: 0;
        width: 50%;
    } */

    /* .hero-pool {
        max-height: 1000px;
    } */
}


/* @media (min-width:0) {
    .matchup-action {

    }
}

@media (min-width:700px) {
    .matchup-action {

    }
} */